@layer base {
    @font-face {
        font-family:"Univers";
        src:url('../fonts/Linotype-UniversNextPro-MediumCm.eot');
        src:url('../fonts/Linotype-UniversNextPro-MediumCm.eot?#iefix') format("embedded-opentype"),
        url('../fonts/Linotype-UniversNextPro-MediumCm.woff') format("woff"),
        url('../fonts/Linotype-UniversNextPro-MediumCm.ttf') format("truetype");
        font-weight:400;
        font-style:normal;
    }

    @font-face {
        font-family:"UniversCond";
        src:url('../fonts/Linotype-UniversNextPro-Cond.eot');
        src:url('../fonts/Linotype-UniversNextPro-Cond.eot?#iefix') format("embedded-opentype"),
        url('../fonts/Linotype-UniversNextPro-Cond.woff') format("woff"),
        url('../fonts/Linotype-UniversNextPro-Cond.ttf') format("truetype");
        font-weight:400;
        font-style:normal;
    }


}